<template>
  <b-table v-if="Array.isArray(stats)" :fields="fields" :items="stats">
  </b-table>
  <table v-else>
    <thead>
      <tr>
        <th class="title">
          Date
        </th>
        <th class="title">
          Name
        </th>
        <th class="title">
          Position
        </th>
        <th class="title">
          Score
        </th>
        <th class="title">
          VSPAR
        </th>
        <th class="title">
          Rounds
        </th>
        <th class="title">
          Winnings
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="cell">
          {{ stats.DATE }}
        </td>
        <td class="cell">
          {{ stats.NAME }}
        </td>
        <td class="cell">
          {{ stats.POSITION }}
        </td>
        <td class="cell">
          {{ stats.SCORE }}
        </td>
        <td class="cell">
          {{ stats.VSPAR }}
        </td>
        <td class="cell">
          {{ stats.ROUNDS }}
        </td>
        <td class="cell">{{ stats.WINNINGS }}{{ stats.CURRENCY }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "playerLastSeason",
  props: ["stats"],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "SCORE", label: "Score" },
        { key: "VSPAR", label: "VSPAR" },
        { key: "ROUNDS", label: "Round" },
        { key: "WINNINGS", label: "Winnings (EUR)" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep thead {
  color: white;
  background-color: #263056;
}
.cell {
  text-align: center;
}
.title {
  text-align: center;
  background-color: #263056;
  color: white;

}
::v-deep tbody > tr:nth-child(even) {
  background-color: #dfe1e6;
} 
::v-deep td{
     text-align: center;
    padding: 6px;
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 22px;
    min-height: 38px;
        font-size: 14px;
}
::v-deep th{
    padding: 13px;
    text-align: center;
    color: #ffffff;
    font-size: 17px;
}
::v-deep td:nth-child(2){
  text-align: left;
}
::v-deep th:nth-child(2){
  text-align: left;
}
</style>
