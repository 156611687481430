<template>
  <div class="playerProfile" v-if="typeof playerinfo === 'object'">
    <div v-if="isMobile(true)">
      <b-img
        class="profileImage"
        :src="
          digitalOceanURL + config.VUE_APP_ID + '/media/photos/' +
          playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE
        "
      ></b-img>
    </div>
    <div>
      
      <b-row >
        <b-col lg='3' md='12' class="playerImg">
          <b-img
            class="mainImg"
            :src="
              digitalOceanURL + config.VUE_APP_ID + '/media/photos/' +
              playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE
            "
          ></b-img>
        </b-col>
        <b-col lg='9' md='12' class="basicInfo">
          <b-row class='fRow'>
            <b-col class='mobPad'>
              <p class="FullName-details">
                {{ playerinfo.MEMBER.FULL_NAME }}
                <span v-if="show_name_pronounce">
                  <b-button @click="namePronounce()" class="namePronounce"
                    ><font-awesome-icon
                      class="icon"
                      :icon="['fa', 'volume-up']"
                  /></b-button>
                </span>
                <img
                  class="flagPlayer-profile"
                  :src="
                    (config.VUE_APP_FLAG_URL +
                      playerinfo.MEMBER.NATIONALITY)
                      | lowercase
                  "
                />
              </p>
            </b-col>
            <b-col>
              <p class="PlayerIndex-backBtn">
                <a href="javascript:history.back()">
                <font-awesome-icon
                class="icon"
                :icon="['fa', 'chevron-left']"
              />  Back to Player Listings
                </a>
              </p>
            </b-col>
          </b-row>
          <b-row class='playDet'>
            <b-col>
              <p class="HeaderTitle">
                Represents
              </p>
              <p v-if="Object.keys(playerinfo.MEMBER.REPRESENTS).length === 0">
                -
              </p>
              <p v-else>
                {{ playerinfo.MEMBER.REPRESENTS }}
              </p>
            </b-col>
            <b-col>
              <p class="HeaderTitle">
                Date of Birth
              </p>
              <p
                v-if="Object.keys(playerinfo.MEMBER.DATE_OF_BIRTH).length === 0"
              >
                -
              </p>
              <p v-else>
                {{ playerinfo.MEMBER.DATE_OF_BIRTH }}
              </p>
            </b-col>
            <b-col>
              <p class="HeaderTitle">
                Height
              </p>
              <p v-if="Object.keys(playerinfo.MEMBER.HEIGHT).length === 0">
                -
              </p>
              <p v-else>
                {{ playerinfo.MEMBER.HEIGHT }}
              </p>
            </b-col>
            <b-col>
              <p class="HeaderTitle">
                Weight
              </p>
              <p v-if="Object.keys(playerinfo.MEMBER.WEIGHT).length === 0">
                -
              </p>
              <p v-else>
                {{ playerinfo.MEMBER.WEIGHT }}
              </p>
            </b-col>
            <b-col>
              <p class="HeaderTitle">
                Twitter
              </p>
              <p v-if="Object.keys(playerinfo.MEMBER.TWITTER).length === 0">
                -
              </p>
              <p v-else>
                {{ playerinfo.MEMBER.TWITTER }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="accordion playerstats-info" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >Statistics <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="table-responsive">
            <PlayerStats :stats="playerinfo.STATISTICS.STATISTIC" />
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-2 variant="info"
            >Results  <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body class="table-responsive">
            <PlayerResults
              :playedSeasons="playerinfo.TIC_SEASONS.SEASON"
              :id='playerinfo.THIS_SEASON_RECORD.SEASON'
              :refno="refno"
              :config="config"
            />
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-3 variant="info"
            >Order of Merit  <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body class="table-responsive">
            <PlayerOrderofMerit :stats="playerinfo.ORDERS_OF_MERIT" />
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-4 variant="info"
            >This Season Record  <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body class="table-responsive">
            <PlayerThisSeasonRecord
              :stats="playerinfo.THIS_SEASON_RECORD.TOURNAMENT"
            />
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-5 variant="info"
            >Last Season Highlights  <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body class="table-responsive">
            <PlayerLastSeasonRecord
              :stats="playerinfo.LAST_SEASON_HIGHLIGHTS.TOURNAMENT"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-6 variant="info"
            >Career Highlights  <font-awesome-icon
              id="sortDown"
              class="icon"
              :icon="['fa', 'sort-down']"
            /></b-button
          >
        </b-card-header>
        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body class="table-responsive">
            <PlayerCareerHighlights
              :stats="playerinfo.CAREER_HIGHLIGHTS.TOURNAMENT"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
  <div v-else>
    <b-row>
      <b-col style="text-align:center; padding: 100px 0 ;">
        <h1>
          No Player Profile
        </h1>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import PlayerStats from "@/components/playerStats.vue";
import PlayerResults from "@/components/playerResults.vue";
import PlayerOrderofMerit from "@/components/playerOrderofMerit.vue";
import PlayerLastSeasonRecord from "@/components/playerLastSeasonRecord.vue";
import PlayerThisSeasonRecord from "@/components/playerThisSeasonRecord.vue";
import PlayerCareerHighlights from "@/components/playerCareerHighlights.vue";

export default {
  name: "playerprofile",
  components: {
    PlayerStats,
    PlayerResults,
    PlayerOrderofMerit,
    PlayerLastSeasonRecord,
    PlayerThisSeasonRecord,
    PlayerCareerHighlights,
  },
  props: ["refno", 'config'],
  data() {
    return {
      playerinfo: [],
      show_name_pronounce: false,
      name_pronounce: ""
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  created() {
    apiCall
      .playersInfo(this.refno)
      .then(({ data }) => {
        this.playerinfo = data;
        this.digitalOceanURL = data.GENERAL.DEFIMAGESAMS3;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
    namePronounce() {
      console.log("play name sound");
      var audio = new Audio(this.name_pronounce);
      audio.play();
    },
  },
};
</script>

<style scoped>
button.btn.namePronounce.btn-secondary {
  background-color: #263056;
  padding: 0.275rem 0.75rem;
}
.profileImage {
  width: 100%;
}
#sortDown {
  position: relative;
  left: 10px;
  top: -2px;
}
.playerProfile{
  padding-top: 50px;
}
.fRow{
  border-bottom: 1px solid #ddd;
  margin-right: -10px;
}
.playDet > .col {
  border-left: 1px solid #ddd;
}
.playDet > .col:first-child {
  border: none;
}
.playDet {
  padding-top: 20px;
}
p.FullName-details {
  font-size: 20pt;
}
.playerstats-info {
  margin-top: 30px;
}
.flagPlayer-profile {
  width: 28px;
  margin-left: 15px;
  box-shadow: 0 0 3px #555;
}
p.HeaderTitle {
  font-weight: bold;
  color: #263056;
}
.PlayerIndex-backBtn {
  float: right;
  margin-right: 100px;
  font-size: 22px;
}
.playerImg {
  max-width: 20%;
  padding-left: 5%;
}

.btn {
  background-color: #263056;
  border-color: #263056;
  border-radius: 0;
}

.btn:hover {
  background-color: white;
  border-color: white;
  color: #263056;
}

.btn-info:focus {
  background-color: white;
  border-color: white;
  color: #263056;
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #263056;
  background-color: white;
  border-color: white;
}

.mainImg {
  max-width: 75%;
}
.card-body{
  padding: 0;
}
.card-header{
  padding: 0!important;
}
::v-deep .table thead th {
  font-size: 13px!important;
  font-weight: 400;
}
@media only screen and (max-width: 990px) {
  .playerProfile {
    padding-top: 0px;
  }
  .mainImg {
    max-width: 75%;
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .playerImg {
    max-width: 75%;
    padding-bottom:20px;
  }
  .basicInfo {
    padding-left: 38px;
  }
  .PlayerIndex-backBtn {
    margin-right: 100px;
    font-size: 16px;
    padding-top: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .PlayerIndex-backBtn {
    margin-right: 5px;
  }
  .mobPad{
    padding-left: 0;
  }
  p.FullName-details{
    font-size: 20px;
  }
  .flagPlayer-profile {
    margin-left: 3px!important;
  }
  .playDet > .col:first-child {
    border: none;
    padding-left: 0px;
  }
  .playDet > .col:last-child {
    padding-right: 0px;
  }
  .playDet {
    padding-top: 20px;
    margin-right: 0;
  }
  ::v-deep th {
    padding: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 17px;
  }
}
</style>
