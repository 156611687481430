<template>
  <div id="players">
    <b-container fluid>
      <b-row class="searchBackground">
        <b-col class="letCol" lg="8">
          <div class="letterSearch">
            <a href="#A">A</a>
            <a href="#B">B</a>
            <a href="#C">C</a>
            <a href="#D">D</a>
            <a href="#E">E</a>
            <a href="#F">F</a>
            <a href="#G">G</a>
            <a href="#H">H</a>
            <a href="#I">I</a>
            <a href="#J">J</a>
            <a href="#K">K</a>
            <a href="#L">L</a>
            <a href="#M">M</a>
            <a href="#N">N</a>
            <a href="#O">O</a>
            <a href="#P">P</a>
            <a href="#Q">Q</a>
            <a href="#R">R</a>
            <a href="#S">S</a>
            <a href="#T">T</a>
            <a href="#U">U</a>
            <a href="#V">V</a>
            <a href="#W">W</a>
            <a href="#X">X</a>
            <a href="#Y">Y</a>
            <a href="#Z">Z</a>
          </div>
        </b-col>
        <b-col lg="4" class="search">
          <b-form-group
            label="Seach"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Find Player..."
              ></b-form-input>


            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="items"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
      >
        <template #cell(name)="row">
          <span :id="row.item.SURNAME.slice(0, 1)">
            <a :href="'/playerprofile/' + row.item.REFNO">
            {{ row.item.FORENAME_1 }} {{ row.item.SURNAME }}
            </a>
          </span>
        </template>
        <template #cell(nat)="row">
          <template v-if="row.item.NAT.length > 0">
            <img
              class="insideflag"
              :src="
                (config.VUE_APP_FLAG_URL +
                  row.item.NAT)
                  | lowercase
              "
            />
          </template>
          <template v-else>
            
          </template>
        </template>
        <template #cell(AGE)="row">
          <!-- {{ getAge() }} -->
          {{ row.item.AGE }}
        </template>
        <template #cell(represents)="row">
          <p v-if="Object.keys(row.item.REPRESENTS).length === 0">
            -
          </p>
          <p v-else>
            {{ row.item.REPRESENTS }}
          </p>
        </template>
        <template #cell(TURNED_PRO)="row">
          {{ row.item.TURNED_PRO.substring(6, 10) }}
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col lg="2" md="3" cols="6">
                <!-- <img class="playerPhoto" :src="'https://info.alpstourgolf.com/media/photos/' + row.item.REFNO + '.jpg'" /> -->
                <img class="playerPhoto" :src=" digitalOceanURL + config.VUE_APP_ID + '/media/photos/' + row.item.REFNO + '.jpg'" />
              </b-col>
              <b-col lg="10" md="9" cols="12">
                <b-row class="fRow">
                  <b-col lg="1" md="3" cols='6' :class="details">
                    <p class="FullNameDropdown"> 
                      {{ row.item.FULL_NAME }}
                    </p>
                  </b-col>
                  <b-col lg="2" md="2" cols="6" :class="details">
                  <template v-if="row.item.NAT.length > 0">
                    <img
                      class="insideflag"
                      :src="
                        (config.VUE_APP_FLAG_URL +
                          row.item.NAT)
                          | lowercase
                      "
                    />
                  </template>
                  <template v-else>

                  </template>
                  </b-col>
                  <b-col lg="2" md="4" cols="12" :class="details">
                    <b-nav-item
                      class="profileLink"
                      :href="'/playerprofile/' + row.item.REFNO"
                    >
                      Full Profile
                    </b-nav-item>
                  </b-col>
                </b-row>
                <b-row class="playDet">
                  <b-col class="leftText">
                    <p class="HeaderTitle">
                      Represents
                    </p>
                    <p>
                      {{ row.item.REPRESENTS }}
                    </p>
                  </b-col>
                  <b-col>
                    <p class="HeaderTitle">
                      Age
                    </p>
                    <p>
                      {{ row.item.AGE }}
                      <!-- getAge() -->
                    </p>
                  </b-col>
                  <b-col>
                    <p class="HeaderTitle">
                      Height
                    </p>
                    <p v-if="Object.keys(row.item.HEIGHT).length === 0">
                      -
                    </p>
                    <p v-else>
                      {{ row.item.HEIGHT }}
                    </p>
                  </b-col>
                  <b-col>
                    <p class="HeaderTitle">
                      Residence
                    </p>
                    <p v-if="Object.keys(row.item.RESIDENCE).length === 0">
                      -
                    </p>
                    <p v-else>
                      {{ row.item.RESIDENCE }}
                    </p>
                  </b-col>
                  <b-col class="twitter">
                    <p class="HeaderTitle">
                      Twitter
                    </p>
                    <p v-if="Object.keys(row.item.TWITTER).length === 0">
                      -
                    </p>
                    <p v-else>
                      {{ row.item.TWITTER }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import moment from 'moment';
export default {
  props:['config'],
  data() {
    return {
      items: [],
      details: "details",
      cell: "cell",
      flag: "flag",
      fields: [
        { key: "FULL_NAME", label: "Name", class: "nameClass" },
        { key: "NAT", label: "Nationality", class: "text-center" },
        { key: "AGE", label: "Age", class: "text-center" },
        { key: "REPRESENTS", label: "Represents", class: "text-center" },
        { key: "TURNED_PRO", label: "Turned Pro", class: "text-center" },
        { key: "actions", label: "More Info" },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  created() {
    apiCall
      .players()
      .then(({ data }) => {
        this.items = data.MEMBERS.PLAYER;
        this.digitalOceanURL = data.GENERAL.DEFIMAGESAMS3;
        console.log(this.items)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    getAge() {
      var years = '';
      this.items.forEach(function (value) {
        var newVal = parseInt(value.DOB)
        years = moment().diff(newVal , 'years',false);
        // console.log('years')
        // console.log(years)
      });
      return years;
    },
    // getAge() { 
    //   // console.log('IN')
    //   // this.items.forEach(function () {
    //   //   var years = moment().diff('04-01-1993' , 'years',false);
    //   //   return years
    //   // })
    //   // return years
    //   // this.items.forEach(function (value) {
    //   //   var ageDifMs = Date.now();
    //   //   // console.log(ageDifMs)
    //   //   var ageDate = new Date(ageDifMs); // miliseconds from epoch
    //   //   // console.log(typeof ageDate.getUTCFullYear())
    //   //   // console.log(value.DOB)
    //   //   var age =  Math.abs(ageDate.getUTCFullYear() -  value.DOB);
    //   //   // console.log(age)
    //   //   return age
    //   //   // return Math.abs(ageDate.getUTCFullYear() -  value.DOB);
    //   // });
    // }
  }
};
</script>

<style scoped>
::v-deep .leftText {
  text-align: left;
  padding-left: 0;
}
::v-deep .FullNameDropdown {
  font-weight: bold;
  text-align: left;
  width: 200px;
}
::v-deep td.nameClass {
  text-align: left;
  padding-left: 35px;
}
::v-deep th.nameClass {
  text-align: left;
  padding-left: 35px;
}
li {
  list-style: none;
}
#players {
  padding-top: 30px;
}
.letterSearch > a {
  color: #fff !important;
  margin: 0px 6px;
  font-size: 16px;
  font-weight: bold;
}
.letterSearch {
  /* display: inline-block !important; */
  display: none!important;
  margin-top: 15px;
}
.letCol {
  text-align: center;
}
p.HeaderTitle {
  font-weight: bold;
  color: #263055;
}
.searchBackground {
  background: #263055;
  height: 60px;
  margin: auto;
}
.profileLink > .nav-link {
  background-color: transparent;
  color: #263055 !important;
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  padding: 0;
  margin-top: 0px;
}
.fRow {
  border-bottom: 1px solid #ddd;
  margin-right: -10px;
}
.playDet > .col {
  border-left: 1px solid #ddd;
}
.playDet > .col:first-child {
  border: none;
}
.playDet {
  padding-top: 20px;
}
.card{
  background-color: transparent;
  border: 0;
}
::v-deep div > label.text-sm-right {
  display: none !important;
}
::v-deep th {
  background-color: #263056;
  width: 17%;
  text-align: center;
  position: sticky;
  top: 0;
  padding: 13px;
  color: #ffffff;
  font-size: 17px;
  font-weight: normal;
}

::v-deep td {
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
}
p {
  margin: 0;
}
::v-deep .nav-link {
  color: #337ab7 !important;
  /* background-color: #263056; */
  /* width: 140px; */
  width: 100%;
}

::v-deep .btn {
  /* color: white;
  background-color: #263056; */
  background-color: transparent;
  color: #000;
  border: 0;
}
::v-deep .btn:active{
  background-color: transparent!important;
  color: #000!important;
}
::v-deep .btn:focus{
  box-shadow: none!important;
}
::v-deep .btn::before {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.playerPhoto {
  width: 75%;
}

.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.insideflag {
  vertical-align: initial;
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.profileButton {
  padding-left: 5%;
}

.details {
  padding-top: 1%;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
}
::v-deep tr:nth-child(even) {
  background-color: #f2f2f2;
}

.search {
  padding: 1%;
}

.mb-0 {
  float: right;
  width: 60%;
}
::v-deep .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #263056;
}
@media (max-width: 767.98px) {
  ::v-deep .details {
    padding-top: 1%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  ::v-deep th.nameClass {
    text-align: left;
    padding-left: 0px!important;
  }
  ::v-deep td.nameClass {
    text-align: left;
    padding-left: 0px;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > ::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: 400;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  ::v-deep td > div {
    text-align: left;
    font-weight: bold;
  }
  ::v-deep .btn {
    background-color: transparent;
    color: #000;
    border: 0;
    font-weight: bold;
    padding-left: 0;
  }
  p.HeaderTitle {
    font-weight: 400;
    color: #263055;
  }
}
@media only screen and (max-width: 768px) {
  .searchBackground {
    height: 100px;
    background-color: #fff;
  }
  .playerPhoto {
    width: 130px;
  }
  #players {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .playDet > .col {
    border-left: 0px solid #ddd;
  }
  ::v-deep .nav-link {
    width: 35%;
  }
  .playerPhoto {
    width: 130px;
  }
  .letterSearch {
    display: none !important;
  }
  ::v-deep td::before {
    text-align: left !important;
  }
  /* ::v-deep td > div {
    text-align: right;
  } */
  .mb-0 {
    width: 100%;
  }
  .playDet {
    padding-top: 20px;
    margin-left: 0px;
  }
  .twitter{
    padding-left: 0;
  }
}
</style>
