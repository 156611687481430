<template>
  <div>
    <div>
      <img class="sponsor mobHide newclass" :src="data.event_sponsor_logo" />
    </div>
    <div :class="drop">
      <b-nav card-header pills>
        <b-dropdown
          v-if="Array.isArray(tm_params.reports.reports_entry)"
          :text="title"
          class="dropdownReports"
        >
          <b-dropdown-item
            v-on:click="changeReport"
            v-for="(reports, index) in filterExemptions"
            :key="index"
            :title="reports.report_url"
            :value="reports.report_title"
            >{{ reports.report_title }}</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          v-else
          text="Please Select a Report"
          class="dropdownReports"
        >
          <b-dropdown-item
            v-on:click="changeReport"
            v-for="(reports, index) in tm_params.reports"
            :key="index"
            :text="title"
            :value="reports.report_title"
            >{{ reports.report_title }}</b-dropdown-item
          >
        </b-dropdown>
        <b-button onclick="window.print()" class="print">Print</b-button>
      </b-nav>
    </div>
    <div class="reports">
      <div
        v-if="
          this.currentReport.indexOf('tmentry') !== -1 ||
            this.currentReport.indexOf('tmdraw') !== -1 ||
            this.currentReport.indexOf('tmresult') !== -1 ||
            this.currentReport.indexOf('tmoomt') !== -1
        "
      >
        <ReportTable
          :data="data"
          :previousData="previousData"
          :home="home"
          :title="title"
          :config="config"
          :team="teamEvent"
          :match="tm_params.match_play"
        />
      </div>
      <div v-else-if="this.currentReport.indexOf('tmlsmon') !== -1 || this.currentReport.indexOf('tmscores') !== -1 || this.currentReport.indexOf('tmcrsts') !== -1">
        <b-tabs content-class="mt-3">
          <b-tab title="Leaderboard" active>
            <ReportTable
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="title"
              :config="config"
              :team="teamEvent"
              :match="tm_params.match_play"
            />
          </b-tab>
          <b-tab title="Hole by Hole">
            <Live :tabs="tabs" :config="config" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Live from "@/views/live.vue";
import ReportTable from "@/components/reportTable.vue";
import apiCall from "@/services/api.js";
export default {
  name: "Reports",
  components: {
    Live,
    ReportTable,
  },
  props: ["config"],
  data() {
    return {
      data: [],
      previousData: [],
      tm_params: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      id: this.$route.query.id,
      code: this.$route.query.code
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        this.callOne()
      );
    },
    updateStuff: async function() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParams(this.id, this.code);
      this.tm_params = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
    },
    callOne() {
      apiCall
        .report(this.reportTitle)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  computed: {
    filterExemptions: function() {
      return this.tm_params.reports.reports_entry.filter(
        (reports_entry) =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
        this.config.VUE_APP_API +
        this.config.VUE_APP_ID +
        "/cache/" +
        "qsc" +
        "/" +
        this.$route.query.id +
        "/" +
        this.$route.query.id +
        "-" +
        this.$route.query.code +
        "-entry-entries.json?randomadd=" +
        new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "qsc" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "qsc" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "qsc" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "qsc" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  created() {
    setInterval(this.updateStuff, 30000);
  },
    async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParamsqsc(this.id, this.code);
      this.tm_params = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.dropdownReports > button:hover {
  background-color: pink !important;
}

.drop {
  padding-left: 25px;
  padding-bottom: 20px;
}
.sponsor {
  margin-top: -8em !important;
  margin-left: 10% !important;
  max-width: 136px;
  vertical-align: middle;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
::v-deep button {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #263056;
  color: #ffffff;
  border-radius: 0px;
  border: 0px;
  height: 30px;
  width: 100%;
  text-align: left;
}
::v-deep button::after {
  float: right;
  margin-top: 8px;
}
.dropdown {
  padding-right: 15px;
}
.print {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428571;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  width: 60px;
}
::v-deep button:hover {
  color: #fff;
  background-color: #263056;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #263056 !important;
  width: 100%;
  border-color: #263056 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
@media only screen and (max-width: 980px) {
  .mobHide {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .dropdown {
    width: 60%;
  }
}
</style>
