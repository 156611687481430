<template>
  <div class="playerarea table-responsive">
    <b-row>
      <b-col lg='6' md='12' class="playerInfomation">
        <h3>
          Tournament Info & Fact Sheets
        </h3>
        <p>
          Dear Players,
        
        <br />
        
          Please find below a list of all tournaments this season. By selecting
          the reports you will be able to find all associated files, i.e:
          Tournament Info and Accommodation Details.
        </p>
      </b-col>
      <b-col lg='6' md='12' class="playerInfomation leftPad">
        <h3 >
          Player Information
        </h3>
        <div>
          <b-dropdown id="dropdown-1" text="Select Report..." class="m-md-2 mob">
            <b-dropdown-item
              v-for="(files, index) in data.tm_params.season_files
                .season_file_details"
              :key="index"
              target="_black"
              :href="
                'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/factsheets/' +
                  data.tm_params.season_code +
                  '/' +
                  files.season_file
              "
            >
              {{ files.season_desc }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-table :items="data.tournaments.tournaments_entry" :fields="fields">
      <template #cell(total_prize_fund)="row">
        <span v-if="Object.keys(row.item.total_prize_fund).length === 0">
          -
        </span>
        <span v-else>
          {{ row.item.total_prize_fund }}
        </span>
      </template>
      <template #cell(reports)="row">
        <b-nav card-header pills>
          <b-dropdown
            id="dropdown-dropleft"
            
            v-if="Array.isArray(row.item.fact_sheets.fact_sheet)"
            text="Report..."
          >
            <b-dropdown-item
              target="_blank"
              :href="reports.fact_sheet_file.replace('https://alps.ocs-ffg.com/', 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/' )"
              v-for="(reports, index) in row.item.fact_sheets.fact_sheet.slice(
                0,
                6
              )"
              :key="index"
              :title="reports.fact_sheet_desc"
            >
              <span v-if="Object.entries(reports.fact_sheet_desc).length">
                {{ reports.fact_sheet_desc }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown id="dropdown-dropleft" dropleft v-else text="report">
            <b-dropdown-item
              target="_blank"
              :href="reports.fact_sheet_file.replace('https://alps.ocs-ffg.com/', 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/' )"
              v-for="(reports, index) in row.item.reports"
              :key="index"
              :title="reports.fact_sheet_desc"
            >
              <span v-if="Object.entries(reports.fact_sheet_desc).length">
                {{ reports.fact_sheet_desc }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </b-nav>
      </template>
      <template #cell(short_name)="row">
        <a
          :href="
            '/tournament/' + row.item.code + '/' + data.tm_params.season_code
          "
        >
          {{ row.item.short_name }}
        </a>
      </template>
      <template #cell(closing_date)="row">
        {{ row.item.closing_date }}<span class="mobHide"> - {{ row.item.closing_time }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "PlayerArea",
  props:['config', 'id'],
  data() {
    return {
      // id: "",
      data: [],
      fields: [
        { key: "start_date", label: "Dates", class: "playerInfo-header datesWidth" },
        { key: "short_name", label: "Tournament", class: "playerInfo-header tournamentWidth"},
        { key: "course", label: "Venue", class: "playerInfo-header" },
        { key: "closing_date", label: "Entries Close", class: "playerInfo-header hideMob" },
        { key: "total_prize_fund", label: "Prize Fund", class: "playerInfo-header" },
        { key: "reports", label: "Reports", class: "playerInfo-header" }
      ]
    };
  },
  // created() {
  //   apiCall
  //     .playersArea()
  //     .then(({ data }) => {
  //       this.data = data;
  //       this.loading = false;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       throw error;
  //     });
  // },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.playersArea(this.id);
      this.data = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
th.playerInfo-header {
  padding-left: 0;
}
ul.dropdown-menu.show {
  font-size: 14px;
  text-align: center;
}
::v-deep #dropdown-dropleft>button {
  padding: 2px;
  font-size: 14px;
}
::v-deep th.playerInfo-header > div{
  font-weight: 400;
}
::v-deep td {
  text-align: center;
  font-size: 14px;
  color: #848792;
  padding: 0;
}

.playerInfomation>h3 {
  font-size: 24px;
  padding-left: 20px;
}
.playerInfomation>p {
  font-size: 14px;
  padding-left: 20px;
}
.playerarea {
  margin-top: 3em;
}
::v-deep th:last-child{
  text-align: left;
  padding-left: 10px;
}
::v-deep td>ul:last-child{
  margin-left: 10px;
}
::v-deep tr>td:nth-child(2){
  text-align: left;
  font-weight: 600;
  width: 20%;
}
::v-deep tr>td:nth-child(3){
  text-align: left;
  width: 20%;
}
::v-deep tr>td:nth-child(4){
  color: black;
}
::v-deep tr>th:nth-child(2){
  text-align: left;
  padding-left: 0px;
}
::v-deep tr>th:nth-child(3){
  text-align: left;
  padding-left: 0px;
}
::v-deep .sheetLink {
  color: black;
}
.dropdown{
  width: 50%;
}
::v-deep #dropdown-dropleft>button{
  padding: 2px;
}
::v-deep .btn {
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 0;
}

::v-deep .btn:after{
  float: right;
  margin-top: 10px;
}

::v-deep th {
  text-align: center;
  background-color: #263056;
  color: white;
  position: sticky;
  top: 0;
  z-index: 111;
  line-height: 31px;
  padding: 8px;
}
.leftPad{
  padding-left: 50px;
}
::v-deep td {
  text-align: center;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
#dropdown-1{
      padding-left: 13px;
}
@media only screen and (max-width: 768px) {
  ::v-deep table{
    margin-top: 15px;
  }
  ::v-deep .dropdown-toggle{
    font-size: 14px;
  }
  ::v-deep .dropdown{
    width: 85%;
  }
    ::v-deep tr>td:nth-child(5){
    display: none;
  }
    ::v-deep tr>th:nth-child(5){
    display: none;
  }
  .mobHide{
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep th.playerInfo-header.datesWidth {
    text-align: left;
    padding-left: 15px;
    width: 88px;
  }
  ::v-deep th.playerInfo-header.tournamentWidth {
    /* display: block; */
    width: 50%;
  }
  ::v-deep th.playerInfo-header.hideMob {
    display: none;
  }
  ::v-deep td.playerInfo-header.hideMob {
    display: none;
  }
  .mob{
    padding-left: 15px;
    width: 97%!important;
  }
  ::v-deep tr>td:nth-child(3){
    display: none;
  }
    ::v-deep tr>th:nth-child(3){
    display: none;
  }
  .leftPad{
    padding-left: 15px;
  }
  ::v-deep #dropdown-dropleft>button {
    margin-top: 3px;
  }
}

</style>
