<template>
  <div id="single">

    <b-container>
      <div id="qschool">
        <div class="topRow qsclassAddThree">
          <div class="q-school-app">
            <div>
              <b-row>
                <b-col lg="6" md="12" sm="12">
                  <div class="EnterNow">To enter <span class="qschoolYear"> Q-School</span> download the Alps Tour App</div>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <b-row class="q-schoolApp-block">
                    <b-col lg="6" md="12" sm="12">
                      <a href="https://apps.apple.com/gb/app/alps-tour-golf/id507475853" target="_blank">
                        <div id="apple"></div>
                      </a>
                    </b-col>
                    <b-col lg="6" md="12" sm="12">
                      <a href="https://play.google.com/store/apps/details?id=com.alps.tour.golf&hl=en_GB&gl=US" target="_blank">
                        <div id="google"></div>
                      </a>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    <div class="paddingBlock">
      <b-row class="blockAppPic">
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-1.png">
          <div class="instructBlock">
            <span class="textBlock">
              To enter Q-School download the Alps Tour App
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-2.png">
          <div class="instructBlock">
            <span class="textBlock classAdded">
              Please press on the profile icon in the top right hand corner
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/10/alps-qschool-3_24_720.png">
          <div class="instructBlock">
            <span class="textBlock">
              Select the players tab on the right and press the "Create New Account" button
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-4.png">
          <div class="instructBlock">
            <span class="textBlock">
              Please press "Register for Q-School"
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-5.png">
          <div class="instructBlock">
            <span class="textBlock">
              Run through the wizard and complete registration
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-6.png">
          <div class="instructBlock">
            <span class="textBlock">
             Go to schedule and press "Q-School"
            </span>
          </div> 
        </b-col>
        <b-col class="blockApp">
          <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/07/Alps-Qschool-7.png">
          <div class="instructBlock">
            <span class="textBlock">
              Enter and pay
            </span>
          </div> 
        </b-col>
      </b-row>
    </div>
      <span class="FilterYear">
        <b-form-select v-model="year" class="m-md-2 yearDropdown q-schoolDrop" v-on:change="changeYear(year)">
          <b-form-select-option 
            class="opt-arrow"
            v-for="(value,
            index) in tm_params.seasons.seasons_entry.slice().reverse()"
            :key="index"
            :value="value.code"
            v-show="value.code > 2020"
          >{{value.desc}}</b-form-select-option>
        </b-form-select>
      </span>
      <span class="FilterFactSheets">
        <div>
          <b-dropdown id="dropdown-1" text="Tournament Info " class="m-md-2 yearDropdown">
            <b-dropdown-item v-for="(tourn, index) in factSheets" :key="index" :href="tourn.fact_sheet_file" target="_blank" v-show="tourn.fact_sheet_desc" class="dropdownArticle">
              {{ tourn.fact_sheet_desc }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- <b-form-select class="m-md-2 yearDropdown q-schoolDrop">
          <b-form-select-option 
            v-for="(sheets,
            index) in factSheets"
            :key="index"
            v-show="sheets.fact_sheet_desc.length > 0"
            :value="sheets.fact_sheet_file"
          >
          {{ sheets.fact_sheet_desc }}
          </b-form-select-option>
        </b-form-select> -->

      </span>
      <template v-if="this.seasonCodePrev == season">
        <div
          class="tableSection"
          v-for="(table, index) in data"
          :key="index"
        >
          <b-link :to="'tournament/' + table.code + '/' + year" class="noUnderline">
            <div class="tableEventName">{{ table.short_name }}</div>
            <table>
              <thead>
                <th>Venue</th>
                <th>Rounds 1 - {{ table.num_of_rounds }}</th>
              </thead>
              <tbody>
                <td>
                  <ul class="tournUL">
                  <li class="liTourn">{{ table.course }}</li>
                  <li class="liTourn" v-if="table.course_city != ''" >{{ table.course_city }},</li>
                  <li class="liTourn" v-if="table.course_country != ''">{{ table.course_country }},</li>
                  </ul>
                </td>
                <td>{{ table.dates }}</td>
              </tbody>
            </table>
          </b-link>
        </div>
      </template>
      <template v-else>
        <div
          class="tableSection"
          v-for="(table, index) in data"
          :key="index"
        >
          <b-link :to="'tournament/' + table.code + '/' + year" class="noUnderline">
            <div class="tableEventName">{{ table.short_name }}</div>
            <table>
              <thead>
                <th>Venue</th>
                <th>Rounds 1 - {{ table.num_of_rounds }}</th>
              </thead>
              <tbody>
                <td>
                  <ul class="tournUL">
                  <li class="liTourn">{{ table.course }}</li>
                  <li class="liTourn" v-if="table.course_city != ''" >{{ table.course_city }},</li>
                  <li class="liTourn" v-if="table.course_country != ''">{{ table.course_country }},</li>
                  </ul>
                </td>
                <td>{{ table.dates }}</td>
              </tbody>
            </table>
          </b-link>
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Single",
  props: ["develop", "config", "season"],
  components: {
  },
  data() {
    return {
      FullData: [],
      filter: null,
      tm_params:[],
      seasonCode: [],
      qscseason:[],
      id:[],
      page: [],
      data: [],
      // year: parseInt(this.qscseason),
      seasonCodePrev: "",
      showQSchool: false,
      factSheets: [],
      isClosed: '',
      year: this.season,
      selected: this.season,
    };
  },
  computed: {

  },
  mounted() {
    this.getSeason();
    setTimeout(() => {
      var d = new Date();
      var pastYear = d.getFullYear() - 1;
      console.log("pastYear");
      console.log(pastYear);
      // axios.get(this.setDev).then(response => {
      //   this.page = response.data;
      //   if (
      //     this.$route.name === "q-school"
      //   ) {
      //     this.getSeason();
      //   }
      // });
      // .then(response => {
      //   // console.log("response");
      //   // console.log(response);
      //   // this.FullData = response.data;
      //   // this.data = response.data.tournaments.tournaments_entry;
      //   // if (typeof this.data === "object") {
      //   //   this.data = [this.data];
      //   // }
      //   // this.seasonCodePrev = response.data.tm_params.season_code;
      // });
    }, 1000);
  },
  methods: {
    seasonDes(id){
      var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
      return year[0].desc
    },
    changeYear: function(year) {
      console.log('Changing Year')
      return (
          axios
          .get(
            process.env.VUE_APP_API + 
              'ffg/' +
              'cache/' +
              'qsc' +
              "/" +
              year +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(response => {
            this.FullData = response.data;
            this.qscseason = response.data.tm_params.season_code;
            this.data = response.data.tournaments.tournaments_entry;
            if (Array.isArray(this.data)) {
              console.log("isArray");
            } else {
              console.log("is not Array");
              this.data = [this.data];
            }
          })
      );
    },


    getSeason() {
      console.log("run this season check");
      let season_req =
      process.env.VUE_APP_API + 
        'ffg/' +
        'cache/' +
        'qsc' +
        "/" +
        "tmticx?randomadd=" +
        new Date().getTime();
        axios.get(season_req).then(response => {
          this.qscseason = response.data.tm_params.season_code;
          this.qscseasons = response.data.tm_params.season_code;
          // console.log()
          if (
            response.data.tm_params.allow_qschool === "W" ||
            response.data.tm_params.allow_qschool === "B"
          ) {
            this.getTournData();
            this.chooseSeason();
          }
        });
    },
    getTournData() {
      let tourn_req =
        process.env.VUE_APP_API + 
          'ffg/' +
          'cache/' +
          'qsc/' +
        this.qscseason +
        "/tmticx?randomadd=" +
        new Date().getTime();
        axios.get(tourn_req).then(response => {
          this.tm_params = response.data;
          this.seasonCode = response.data.tm_params.season_code;
          console.log("this.seasonCode")
          console.log(this.seasonCode)
          this.FullData = response.data;
          this.data = response.data.tournaments.tournaments_entry;
            if (Array.isArray(this.data)) {
              console.log("isArray");
            } else {
              console.log("is not Array");
              this.data = [this.data];
            }
          for (let i = 0; i < this.data.length; i++) {
              console.log(this.data[i].is_closed);
              this.isClosed = this.data[i].is_closed
              console.log("this.isClosed")
              console.log(this.isClosed)
          }
          let arry = this.data;
          let lastElement = arry[arry.length - 1];
          this.factSheets = lastElement.fact_sheets.fact_sheet
          if (typeof this.data === "object") {
            // this.data = [this.data];
          }
          this.seasonCodePrev = response.data.tm_params.season_code;
        });
    },
    chooseSeason() {
      if (this.qscseason !== this.season) {
        this.newseason = parseInt(this.season) - 1
        console.log("this.newseason")
        console.log(this.newseason)
      } else {
        this.newseason = parseInt(this.season)
        console.log("this.newseason2")
        console.log(this.newseason)
      }
    },
  }
};
</script>

<style scoped>
.paddingBlock {
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 100px;
  margin-bottom: 50px;
}
.imageBack {
  display: block;
  width: 100%;
}
.numberSec {
  color: #fff;
  background: #263056;
  width: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: auto;
  border-radius: 20px;
}
.instructBlock {
  text-align: center;
  /* margin-top: -20px; */
}
span.numBlock {
  text-align: center;
}
span.textBlock {
  text-align: center;
  color: #848792;
  font-size: 12px;
  line-height: 1.4!important;
  display: block;
  margin-top: 20px;
}
img.imageBack {
    width: 100%;
}
.topRow.qsclassAddThree {
    /* background: pink; */
    display: contents;
  margin-bottom: 50px;
}
div#qschool {
  margin-bottom: 50px;
}
.EnterNow {
  color: #fff;
  margin: 20px;
  font-size: 15pt;
  padding-left: 25px;
}
.q-school-app {
  background-color: #263056;
  padding-top: 15px;
  padding-bottom: 20px;
}
.topRow {
  margin-bottom: 30px;
  width: 780px;
  margin: auto;
}
#apple {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASB.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#apple:hover {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASW.png');
}
#google {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPB.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#google:hover {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPW.png');
}
.marginTv {
  margin-top: 130px;
}
.noHeight {
  height: auto!important;
}
::v-deep div#news {
  margin-top: 0px!important;
}
a.noUnderline:hover {
  text-decoration: none;
}
.titleiest {
  width: 120px;
  float: right;
  margin-top: 30px;
}
::v-deep p {
  color: #707070;
  font-size: 14px;
}
::v-deep h6 {
  font-size: 18px;
  font-weight: 500;
  color: #0A3F7F;
}
table {
  border-collapse: collapse;
  color: #707070;
  font-size: 14px;
}
.topTitle {
  margin-top: 40px;
}
.FilterFactSheets {
  float: right;
}
a.sheetlink {
  color: #337ab7!important;
}
a.sheetlink:hover {
  color: #337ab7!important;
  text-decoration: none;
}
h2.AsianTourDownload {
  color: #fff;
  font-size: 20px;
  margin-top: 11px;
}
.backgroundDownload {
  background-color: #133f7b;
  padding: 30px;
  margin: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.appleStoreImages {
  background-image: url('https://assets.asiantour.com/asian-tour/2023/07/apple.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.appleStoreImages:hover {
  background-image: url('https://assets.asiantour.com/asian-tour/2023/07/appleHover.png');
}
.googlePlayImages {
  background-image: url('https://assets.asiantour.com/asian-tour/2023/07/google.png');
  height: 75px;
  z-index: 9999;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.googlePlayImages:hover {
  background-image: url('https://assets.asiantour.com/asian-tour/2023/07/googleHover.png');
}
.topRegSection {
  margin-top: 30px;
}
.textblock {
  text-align: center;
  padding-top: 20px;
}
span.RegText {
  text-align: center;
}
.secondRegSection {
  margin-top: 30px;
  margin-bottom: 60px;
}
.qschoolReg {
  width: 100%;
}
::v-deep .dropdown-toggle::after {
  float: right;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-top: 8px;
}
::v-deep .q-schoolDrop {
  width: 204px;
  padding-left: 0;
  margin-left: 0px !important;
  text-align: left;
  border-radius: 0;
  color: #aaa !important;
  background-color: transparent;
  /* width: 100%; */
  border-radius: 0 !important;
  border: 1px solid #c7c7cc;
  height: 34px;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center/9px 6px no-repeat;
}
::v-deep button, button:hover {
  color: #aaa!important;
  background-color: transparent;
  width: 100%;
  border-radius: 0!important;
  border: 1px solid #c7c7cc;
  height: 34px;
  font-size: 14px;
  text-align: left;
}
ul.tournUL {
  padding-left: 0;
  margin-bottom: 0;
}
li.liTourn {
  list-style-type: none;
}
.tableSection {
  margin-top: 30px;
  margin-bottom: 30px;
}
tbody {
  background-color: #e9e7eb!important;
}
::v-deep thead {
  background: #7aafef!important;
  color: #fff;
}
.tableEventName {
  background-color: #0b3f7e!important;
  padding: 0 0 0 10px;
  color: #fff;
  padding: 15px 15px 15px 15px;
  font-size: 13pt;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 0.75rem;
  width: 50%;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep img.size-medium.wp-image-19873.alignnone {
  width: 100%;
  height: 100%;
}
.qschoolBannerImage {
  width: 100%;
  margin-bottom: 50px;
}
.q-schoolButton-form {
  color: white !important;
  background-color: #7aafef;
  margin-top: 0%;
  font-size: 1.4vh !important;
  border-radius: 5px;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  float: right;
}
.q-schoolButton-form:hover {
  color: white !important;
  background-color: #000;
  margin-top: 0%;
  font-size: 1.4vh !important;
  border-radius: 5px;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  float: right;
}
.enterQ-school-Text {
  color: #fff;
  margin: 20px;
  font-size: 14pt;
  line-height: 2;
}
.q-schoolTopButton {
  background-color: #0b3f7e;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-radius: 15px;
}
.bannerFeatured {
  width: 100%;
}
.blurb {
  font-size: 11pt;
  margin-top: 30px;
}
.fullCourseName {
  font-weight: 400;
  text-align: left;
  font-size: 18px;
}
.fullTournName {
  font-size: 40px;
  text-align: left;
  margin-bottom: 10px;
}
.overlay {
  padding-left: 6%;
  padding-top: 0;
  margin-top: -30px;
}
.splashText {
  margin-top: -17%;
  margin-left: 13%;
  width: 80%;
  position: absolute;
  color: #fff;
}
.splash {
  width: 100%;
  opacity: 0.7;
}
.blackO {
  background-color: #000;
  margin-top: 50px;
}
.splashRow {
  overflow: hidden;
  height: 500px;
}
.topMiddleBlock {
  margin-left: 0;
  margin-right: 0;
}
.partnerTitle {
  font-weight: bold;
}
#partners {
  background-color: #f1f1f1;
}
.title {
  color: #0A3F7F;
  font-weight: 500;
  font-size: 20px;
  background-color: transparent!important;
  text-align: left;
}
.logo {
  width: 120px;
  float: right;
}
.content {
  padding-bottom: 3%;
  padding-top: 3%;
}
.sponImg {
  width: auto !important;
  height: 152px !important;
  padding-bottom: 2%;
}
.sponTitle {
  padding-bottom: 2%;
  font-size: 14pt;
}
a {
  color: #0b3f7e !important;
}
.blurb {
  font-size: 11pt;
}
.develop {
  color: #76c3bd !important;
}

@media only screen and (max-width: 768px) {
  .marginTv {
    display: none;
  }
  .q-schoolDrop {
    width: 204px;
    padding-left: 0;
    margin-left: 0px !important;
    text-align: left;
    display: inline;
  }
  .FilterFactSheets {
    float: none;
    margin-top: 20px;
    display: block;
  }
  .textblock {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .splashRow {
    overflow: hidden;
    height: auto;
  }
  .fullTournName {
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
  .overlay {
    padding-left: 6%;
    padding-top: 0;
    margin-top: -4px;
  }
}
</style>
