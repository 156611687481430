<template>
  <div>
    <template>
      <div>
        <div class="splashHeight"  v-if="spon === true || this.$route.name === 'news-letter-2020-signup'">
          <b-img class='splash' :src='pageData[0].better_featured_image.source_url'></b-img>
        </div>
        <b-container class="AllMargin">
        <h3 class="headerSize" v-if='$route.name == "about-let"' v-html="pageData[0].title.rendered">
        </h3>
        <template v-if="this.$route.name === 'news-letter-2020-signup'"></template>
        <template v-else>
          <h3 v-if='spon === false' v-html="pageData[0].title.rendered"></h3>
        </template>
        <span v-if="this.$route.name === 'news-letter-2020-signup'">
          <div class='pad' :class="{newsPad: this.$route.name === 'news-letter-2020-signup'}">
            <h1 color="#000">Ladies European Tour Newsletter </h1>
            <form class="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="2BE4EF332AA2E32596E38B640E9056193AB2FBD75036EE01F295322C8185F349F7EDC456DF98C013E6E65A48D349D0493EC095A830BAC4C62416746DF3FB3AD2">
              <div>
                <div>
                  <label>First Name </label>
                  <input aria-label="First Name" id="fieldsthhyk" maxlength="200" name="cm-f-sthhyk">
                </div>
                <div>
                  <label>Last Name </label>
                  <input aria-label="Last Name" id="fieldsthhyu" maxlength="200" name="cm-f-sthhyu">
                </div>
                <div>
                  <label>Email </label>
                  <input autocomplete="Email" aria-label="Email" class="js-cm-email-input" id="fieldEmail" maxlength="200" name="cm-zkkay-zkkay" required="" type="email">
                </div>
                <fieldset>
                  <label>LET Partner Offers </label>
                  <div>
                    <input id="2049365" name="cm-fo-sthhyr" type="checkbox" value="2049365">
                    <label for="2049365">Yes, I would like to receive special offers from Ladies European Tour partners</label>
                  </div>
                </fieldset>
              </div>
              <button class="submitNewletter" type="submit">Subscribe</button>
            </form>
          </div>
          <script type="application/javascript" src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
        </span>
        <span v-html='pageData[0].content.rendered'></span>
        </b-container>
      </div>
    </template>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "single",
  props: ["id"],
  components: {
  },
  data() {
    return {
      pageData: [],
    };
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages?slug=" +
          this.$route.name + "&randomadd=" +
              new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.pageData = response.data;
      });
  },
  computed:{
    spon:function(spon){
      if(this.$route.name === 'oceantee' || this.$route.name === 'about-let')
      spon = true
      else spon = false
      return spon
    }
  }
};
</script>

<style scoped>
.namesLink {
  color: #24292e;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 30px;
  text-decoration: none!important;
}
.namesLink:hover {
  color: #d60061;
  font-weight: 500;
  text-decoration: none!important;
}
.playerImage {
  width: 100%;
}
::v-deep a.latest {
  color: #d60061;
  font-weight: bold;
}
.extrapad {
  margin-top: 30px;
}
.lacoste_social {
  margin-bottom: 20px;
}
.lacoste_social .social a {
  float: left;
  padding: 0px 10px;
  color: #01487a;
  font-size: 2.5em;
}
.lacostTrophy {
  width: 100%;
  padding-bottom: 10px;
}
.TopTitleLacost {
  margin-top: 30px;
  margin-bottom: 30px;
}
/* h3.lacostH3 {
  font-size: 24px;
} */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 15px;
}
.newsPad {
  padding-top: 50px;
}
.newsPad >h1{
  padding-bottom: 40px;
}
.splashHeight {
  height: 600px;
  overflow: hidden;
}
.headerSize {
  font-size: 1.75rem;
  font-weight: 400;
  border-bottom: 0;
  margin-top: 60px;
}
::v-deep h2, .h2 {
  font-size: 2rem;
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  font-weight: 400;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 16px;
  text-transform: initial;
  font-family: Helvetica,Arial!important;
  line-height: 32px;
}
.splash{
  width: 100%;
}
::v-deep img{
  width: 100%;
  height: auto;
}
::v-deep h2, .h2 {
  font-size: 24px;
  /* font-weight: 300!important; */
}
h3{
  clear: both;
  /* color: #24292e; */
  font-weight: 600;
  margin-top: 24px;
  /* margin-bottom: 15px;  */
  font-size: 3rem;
  border-bottom: 1px solid #eaecef;
  color: #000;
  margin-bottom: 50px;
}
button.submitNewletter {
  background-color: #01477b;
  color: #ffffff;
  border: none;
  width: 150px;
  text-transform: uppercase;
  margin-top: 15px;
}
input{
  line-height: normal;
  display: block;
  margin-bottom: 1em;
}
.pad{
  padding-bottom: 50px;
}
::v-deep h5 {
  font-size: 14px;
  font-weight: 300;
}
::v-deep h2.wp-block-heading.headingBlock {
  padding-bottom: 25px;
  padding-top: 25px;
}
.AllMargin {
  margin-bottom: 60px;
}
</style>
