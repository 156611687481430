<template>
  <div class="tour">
    <div class="top">
      <b-img
        v-if="tourinfo.course_images === 'Y'"
        :class="[splash]"
        :src="
            'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/courses/' +
            id +
            '/' +
            tourinfo.course_code +
            '/' +
            tourinfo.course_image_file_1
        "
      >
      </b-img>
      <b-img v-else :src="config.VUE_APP_DEFAULT_COURSE" :class="[splash]">
      </b-img>
      <b-row :class="[splashText]">
        <b-col :class="[overlay]" class="leftCol" lg="6" md="12" cols="12">
          <b-row>
            <b-col lg="4" md="4" cols="6">
              <h2 class="fullName-width">
                {{ tourinfo.full_name }}
              </h2>
              <h4 class="courseName-width">
                {{ tourinfo.course_name }}
              </h4>
              <p v-if="Object.keys(tourinfo.sponsor_name).length === 0">
                -
              </p>
              <p v-else>In Partnership with {{ tourinfo.sponsor_name }}</p>
            </b-col>

            <b-col style="text-align:center;" lg="8" md="8" cols="6">
              <template v-if="tourinfo.sponsor_website.length > 0">
                <b-nav-item class="noLink" :href="tourinfo.sponsor_website" target="_blank">
                  <b-img :class="[logo]" :src="tourinfo.sponsor_logo"></b-img>
                </b-nav-item>
              </template>
              <template v-else>
                <b-img :class="[logo]" :src="tourinfo.sponsor_logo"></b-img>
              </template>
              <!-- <b-img :class="[logo]" :src="tourinfo.sponsor_logo.replace('http://alps.ocs-ffg.com/', this.digitalOceanURL + 'ffg' + '/').replace('https://alps.ocs-ffg.com/', this.digitalOceanURL + 'ffg' + '/')"></b-img> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <b-col class="leftCol" style="padding-right:1px;">
          <h2 class="splashPad">
            Tournament Info
          </h2>
          <b-row>
            <b-col lg="8">
              <b-row>
                <b-col>
                  <h2 class="underLine">
                    Dates
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.dates }}
                  </h3>
                </b-col>
              </b-row>
              <b-row class="rowPad">
                <b-col>
                  <h2>
                    <span class="underLine">Rounds</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.rounds }}
                  </h3>
                </b-col>
              </b-row>
              <b-row class="rowPad">
                <b-col>
                  <h2>
                    <span class="underLine">Prize Fund</span>
                  </h2>
                  <h3 class="valuesize">
                    {{ tourinfo.total_prize_fund }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="4"
              v-if="
                Object.keys(
                  tourinfo.previous_winners.previous_winners_entry.cc_code_1
                ).length !== 0
              "
            >
              <h4 class="underLine">
                Previous Winner
              </h4>
              <p
                v-if="
                  Object.keys(
                    tourinfo.previous_winners.previous_winners_entry.cc_code_1
                  ).length === 0
                "
              >
                -
              </p>
              <b-img
                class="mainImg"
                v-else
                :src="
                  digitalOceanURL + config.VUE_APP_ID + '/media/photos/' +
                  tourinfo.previous_winners.previous_winners_entry.cc_code_1 + '.jpg'
                "
              >
              </b-img>
              <p
                v-if="
                  Object.keys(
                    tourinfo.previous_winners.previous_winners_entry.cc_name_1
                  ).length === 0
                "
              >
                -
              </p>
              <p v-else>
                <a
                  :href="
                    '/playerprofile/' +
                      tourinfo.previous_winners.previous_winners_entry.cc_code_1
                  "
                >
                  {{
                    tourinfo.previous_winners.previous_winners_entry.cc_name_1
                  }}
                </a>
              </p>
            </b-col>
            <b-col v-if="tourinfo.winner_found === 'Y'">
              <h4 class="underLine">
                Winner
              </h4>
              <template v-if="tourinfo.winner_image == 'Y'">
                <b-img
                  class="mainImg"
                  :src="
                    'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' +
                    tourinfo.winner_code + '.jpg'
                  "
                ></b-img>
              </template>
              <template v-else>
                <b-img
                  class="mainImg"
                  :src="
                    'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/06/dummy-image.jpg'
                  "
                ></b-img>
              </template>
              <p>
                <a :href="'/playerprofile/' + tourinfo.winner_code">
                  {{ tourinfo.winner_name }}
                </a>
              </p>
            </b-col>
            <b-col v-else> 
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3 class="underLine ReportsBlock">
                Reports
              </h3>
              <template v-if="this.class_group == 'QSC'" class="qsc">
                <tempate v-if="tourinfo.reports === ''">
                  <b-row> </b-row>
                </tempate>
                <template
                  v-else-if="Array.isArray(tourinfo.reports.reports_entry)"
                >
                  <b-row class="reportRow">
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reportsqsc',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(0, 3)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item>
                    </b-col>
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reportsqsc',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(3, 6)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reportsqsc',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(6, 9)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                    <b-col>
                      <b-nav-item
                        :to="{
                          name: 'reportsqsc',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        class="reportButton"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(9, 10)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                  </b-row>
                </template>
                <template v-else>
                  <b-row>
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reportsqsc',
                          query: {
                            url: tourinfo.reports.reports_entry.report_url,
                            id: id,
                            code: course_code,
                            title: tourinfo.reports.reports_entry.report_title,
                          },
                        }"
                      >
                        {{ tourinfo.reports.reports_entry.report_title }}
                      </b-nav-item>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <template v-else>
                <tempate v-if="tourinfo.reports === ''">
                  <b-row> </b-row>
                </tempate>
                <template
                  v-else-if="Array.isArray(tourinfo.reports.reports_entry)"
                >
                  <b-row class="reportRow">
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reports',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(0, 3)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item>
                    </b-col>
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reports',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(3, 6)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reports',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(6, 9)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                    <b-col>
                      <b-nav-item
                        :to="{
                          name: 'reports',
                          query: {
                            url: reports.report_url,
                            id: id,
                            code: course_code,
                            title: reports.report_title,
                          },
                        }"
                        class="reportButton"
                        v-for="(reports,
                        index) in tourinfo.reports.reports_entry.slice(9, 10)"
                        :key="index"
                      >
                        {{ reports.report_title }}
                      </b-nav-item></b-col
                    >
                  </b-row>
                </template>
                <template v-else>
                  <b-row>
                    <b-col>
                      <b-nav-item
                        class="reportButton"
                        :to="{
                          name: 'reports',
                          query: {
                            url: tourinfo.reports.reports_entry.report_url,
                            id: id,
                            code: course_code,
                            title: tourinfo.reports.reports_entry.report_title,
                          },
                        }"
                      >
                        {{ tourinfo.reports.reports_entry.report_title }}
                      </b-nav-item>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="leftCol">
          <h2 class="splashPad">
            About the Course
          </h2>
          <b-row>
            <b-col lg="6">
              <h3 class="underLine">
                Course Name
              </h3>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_name }}
              </h3>
              <h3 class="underLine">
                City
              </h3>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_city }}
              </h3>
              <h3 class="underLine">
                Country
              </h3>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_country }}
              </h3>
              <h3 class="underLine">
                Website
              </h3>
              <h3 class="valuesize rightside-changes">
                <a
                  :href="tourinfo.course_website"
                  rel="noopener"
                  target="_blank"
                >
                  {{ tourinfo.course_website }}
                </a>
              </h3>
              <h3 class="">
                <span class="underLine">Par</span>
              </h3>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_total_par }}
              </h3>
            </b-col>
            <b-col v-if="tourinfo.course_code === 'TBC'"> </b-col>
            <!-- <b-col lg="6" md="12">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    id="gmap_canvas"
                    :src="
                      config.VUE_APP_MAPS_URL +
                        mapRef +
                        '&t=&z=13&ie=UTF8&iwloc=&output=embed'
                    "
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe
                  ><a href="https://yt2.org" rel="noopener" target="_blank"></a
                  ><br />
                </div>
              </div>
            </b-col> -->
            <b-row>
              <b-col class="paragraph-Padding">
                <p v-if="Object.keys(tourinfo.course_para1).length === 0"></p>
                <p>
                  {{ tourinfo.course_para1 }}
                </p>
                <p v-if="Object.keys(tourinfo.course_para2).length === 0"></p>
                <p>
                  {{ tourinfo.course_para2 }}
                </p>
                <p v-if="Object.keys(tourinfo.course_para3).length === 0"></p>
                <p v-else>
                  {{ tourinfo.course_para3 }}
                </p>
                <p v-if="Object.keys(tourinfo.course_para4).length === 0"></p>
                <p>
                  {{ tourinfo.course_para4 }}
                </p>
              </b-col>
            </b-row>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="tourInfoOverview" v-if="tourinfo.tourn_desc_found == 'Y'">
      <h2 class="tourInfoHeading">Overview</h2>
        <p class="tourndesc" v-html="tourinfo.tourn_desc">
      </p>
    </div>
    <div class="holes">
      <b-row>
        <b-col lg="6" md="12">
          <table class="courseinfo">
            <thead>
              <tr>
                <th>
                  Hole
                </th>
                <th>
                  1
                </th>
                <th>
                  2
                </th>
                <th>
                  3
                </th>
                <th>
                  4
                </th>
                <th>
                  5
                </th>
                <th>
                  6
                </th>
                <th>
                  7
                </th>
                <th>
                  8
                </th>
                <th>
                  9
                </th>
                <th>
                  Out
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Par
                </td>
                <td
                  v-for="(number, index) in tourinfo.course_par
                    .split(',')
                    .slice(0, 9)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_out_par.slice(1, 5) }}
                </td>
              </tr>
              <tr>
                <td>
                  Yards
                </td>
                <td
                  v-for="(number, index) in tourinfo.course_length_yards
                    .split(',')
                    .slice(0, 9)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{outVal(tourinfo.course_length_yards)}}
                </td>
              </tr>
              <tr>
                <td>
                  Meters
                </td>
                <td
                  v-for="(number, index) in tourinfo.course_length_meters
                    .split(',')
                    .slice(0, 9)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{ outVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col lg="6" md="12">
          <table class="courseinfo">
            <thead>
              <tr>
                <th>
                  10
                </th>
                <th>
                  11
                </th>
                <th>
                  12
                </th>
                <th>
                  13
                </th>
                <th>
                  14
                </th>
                <th>
                  15
                </th>
                <th>
                  16
                </th>
                <th>
                  17
                </th>
                <th>
                  18
                </th>
                <th>
                  In
                </th>
                <th>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(number, index) in tourinfo.course_par
                    .split(',')
                    .slice(9, 18)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_in_par.slice(1, 5) }}
                </td>
                <td>
                  {{ tourinfo.course_total_par }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(number, index) in tourinfo.course_length_yards
                    .split(',')
                    .slice(9, 18)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{inVal(this.tourinfo.course_length_yards)}}
                </td>
                <td>
                  {{ totalVal(this.tourinfo.course_length_yards)}}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(number, index) in tourinfo.course_length_meters
                    .split(',')
                    .slice(9, 18)"
                  :key="index"
                >
                  {{ number }}
                </td>
                <td>
                  {{ inVal(tourinfo.course_length_meters) }}
                </td>
                <td>
                  {{ totalVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Tournament",
  props: ["course_code", "id", "code", "config"],
  data() {
    return {
      tourinfo: [],
      class_group: '',
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
    };
  },
  computed: {
    // mapRef: function() {
    //   if (this.tourinfo.clubs.length)
    //     return this.tourinfo.clubs.clubs_entry.rep;
    //   else return this.tourinfo.course_name;
    // },


  },
  methods:{
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
   inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  // created(id, code) {
  //   id = this.id
  //   code = this.course_code
  //   apiCall
  //     .tmParams(id, code)
  //     .then(({ data }) => {
  //       this.tourinfo = data;
  //       this.loading = false;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       throw error;
  //     });
  // },
  async mounted(id, code) {
    id = this.id
    code = this.course_code
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParams(id, code)
      this.tourinfo = first_call.data;
      this.class_group = first_call.data.class_group;
        console.log("this.class_group")
        console.log(this.class_group)
      if (this.class_group == 'ALP') {
       this.digitalOceanURL = first_call.data.tm_params.DEFIMAGESAMS3; 
      }
      console.log("this.digitalOceanURL")
      console.log(this.digitalOceanURL)
      this.loading = false;
      // Second call
      if (this.class_group !== 'ALP') {
        let second_call = await apiCall.tmParamsqsc(id, code)
        this.tourinfo = second_call.data;
        this.digitalOceanURL = second_call.data.tm_params.DEFIMAGESAMS3;
        this.class_group = second_call.data.class_group;
        console.log("this.class_group 747")
        console.log(this.class_group)
        this.loading = false; 
      } else {
        console.log('Error')
      }
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.tourndesc {
  padding-left: 15px;
}
.tourInfoOverview {
  margin-top: 2em;
}
h2.tourInfoHeading{
  font-size: 25px;
  font-weight: 400;
  color: #848792;
  padding-left: 15px;
}
.noLink > a {
  display: contents;
}
.ReportsBlock {
  margin-bottom: 30px;
}
.paragraph-Padding {
  padding-left: 30px;
  padding-right: 30px;
}
.top {
  height: 395px;
  overflow: hidden;
}
.tour {
  padding-top: 20px;
}
.holes {
  padding: 0 10px;
  padding-top: 25px;
  padding-bottom: 45px;
}
.rightside-changes {
  margin-bottom: 20px;
}
h3.valuesize {
  font-size: 24px;
}
h4.courseName-width {
  font-size: 18px;
}
h2.fullName-width {
  width: 400px;
}
.splash {
  width: 100%;
}
.leftCol > .row {
  padding-left: 15px;
  padding-right: 15px;
}
.underLine {
  /* text-decoration: underline; */
  font-size: 18px;
  font-weight: 400;
  color: #848792;
}

.courseinfo {
  width: 100%;
}

.splashText {
  margin-top: -20%;
  color: white;
}

.logo {
  /* width: 170px; */
  width: 120px;
  padding-bottom: 25px;
  float: right;
  margin-top: 60px;
}

.overlay {
  background-color: rgba(38, 48, 86, 0.6);
  padding-left: 4%;
  padding-top: 2%;
}

.splashPad {
  text-align: center;
  color: white;
  background-color: #263056;
  padding-top: 5px;
  padding-bottom: 3px;
}

.MainInfo {
  padding-left: calc(3vw + 3vh);
  padding-top: calc(2vw + 2vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(2vw + 2vh);
}

.courseStats {
  padding: 10px;
  margin-bottom: 2em;
  margin-top: 1em;
}

.rowPad {
  padding-bottom: 1%;
}
tr:nth-of-type(odd) {
  background-color: #dfe1e6;
}
thead > tr {
  background-color: white !important;
  font-weight: bold;
  font-size: 16px !important;
}

td {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  vertical-align: middle;
  font-size: 16px !important;
}
.reportButton {
  font-size: calc(0.5vh + 0.5vw);
  /* padding-left: 0; */
  /* margin-top: 0; */
  display: block;
  text-align: center;
  background: #263056;
  color: #fff;
  margin-bottom: 15px;
}
.reportButton:hover {
  font-size: calc(0.5vh + 0.5vw);
  /* padding-left: 0; */
  /* margin-top: 0; */
  display: block;
  text-align: center;
  background: #337ab7;
  color: #fff;
  margin-bottom: 15px;
}
.reportButton > a {
  text-decoration: none!important;
  color: #fff!important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.courseStats > .col:first-child {
  padding-right: 0;
}
.courseStats > .col:last-child {
  padding-left: 0;
}
.mainImg {
  max-width: 50%;
}

#gmap_canvas {
  height: 300px;
  width: 300px;
}
.desc {
  font-size: 14px;
  padding: 35px;
}
@media only screen and (max-width: 1024px) {
  #gmap_canvas {
    height: 250px;
    width: 200px;
  }
  .splash {
    height: 300px;
  }
  .top {
    height: 300px;
  }
  .splashText {
    margin-top: -25%;
  }

  .splashPad {
    /* padding-top: 8%; */
    padding-top: 4%;
    padding-bottom: 4%;
    font-size: 12pt;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .holes {
    padding: 0 10px;
    padding-top: 15px;
    padding-bottom: 45px;
  }
  td {
    /* padding-top: 11px !important; */
    padding-right: 6px !important;
    /* padding-bottom: 11px !important; */
    vertical-align: middle;
    font-size: 16px !important;
  }
  #gmap_canvas {
    height: 250px;
    width: 300px;
  }
  .splashText {
    margin-top: -31%;
  }
  thead > tr > th {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}
@media only screen and (max-width: 480px) {
  h2.fullName-width {
    width: auto;
  }
  .splash {
    height: 175px;
  }
  .top {
    height: 500px;
  }

  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
    margin-right: 10px;
  }
  .reportRow {
    padding-bottom: 10%;
  }
  .logo {
    width: 80px;
    padding: 12px;
  }
  .top {
    height: 175px;
  }
  .splashText[data-v-14fba303] {
    margin-top: -38%;
  }
  .row {
    margin: 0;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
