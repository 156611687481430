<template>
  <div class="youTube">
    <YouTube/>
  </div>
</template>
<script>
import YouTube from '@/components/youTube.vue'

export default {
  name:'video',
  components:{
YouTube
  }
}
</script>

<style scoped>
 .youTube{
   padding: 60px 100px;
 }
 @media only screen and (max-width: 480px) {
  .youTube{
   padding: 60px 10px;
 }
}
</style>
