<template>
  <b-container class="newslist">
    <div class="space" v-for="(posts, index) in posts" :key="index">
    <b-row >
      <b-col lg="10" class="order-2">
        <h1 class="newsTitle">
          <span v-html="posts.title.rendered"></span>
        </h1>
        <span v-html="posts.excerpt.rendered" class="newsarticle-desc"></span>
        <b-nav-item class="fullStory" :to="'/news/' + posts.id">
          Read More
        </b-nav-item>
      </b-col>
      <b-col class="order-1">
        <b-img
          v-if="posts.better_featured_image !== null"
          :src="
            config.VUE_APP_WP_DIGITAL_OCEAN +
              posts.better_featured_image.media_details.file
          "
          fluid
          alt="Responsive image"
        />
      </b-col>
    </b-row>
    <br>
    <hr>
    </div>
  </b-container>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "posts",
  props: ["config"],
  data() {
    return {
      posts: [],
    };
  },
      created() {
    const json = "wp/v2/posts?randomadd=" +
          new Date().getTime();;
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.newslist {
  margin-top: 2em;
  padding: 0 50px;
}
.fullStory {
  margin-bottom: -20px;
  margin-top: -40px;
  text-decoration: underline;
}
.newsarticle-desc {
  font-size: 16px;
}
h1.newsTitle {
  color: #263056;
  font-size: 30px;
}
::v-deep .nav-link {
  background-color: transparent;
  /* width: 21%; */
  text-align: left;
  font-size: 14px;
  padding-left: 0;
}

.space {
  padding-bottom: 2%;
  padding-top: 2%;
}
hr{
      width: 80%;
}
@media only screen and (max-width: 480px) {
  h1.newsTitle {
    color: #263056;
    font-size: 30px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    width: 30%;
    text-align: left;
    padding-top: 25px;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
  .newslist{
    padding: 0 26px;
  }
}
</style>
