<template>
  <b-card-body class="text-center">
    <div class="table-responsive">
      <b-table :items="data" :fields="fields" :tbody-tr-class="rowClass">
        <template #cell(reports)="row">
          <b-nav card-header pills>
            <template v-if="row.item.reports.reports_entry == undefined">

            </template>
            <template v-else>
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                v-if="Array.isArray(row.item.reports.reports_entry)"
                text="Select Report..."
              >
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title: reports.report_title,
                    },
                  }"
                  v-for="(reports, index) in row.item.reports.reports_entry"
                  :key="index"
                  :title="reports.report_title"
                  v-show="reports.report_title !== 'Statistics Tables' && reports.report_title !== 'Course Statistics' && reports.report_title !== 'Scoring Statistics'">
                  {{ reports.report_title }}
                  </b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                v-else
                text="Select Report..."
              >
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title: reports.report_title,
                    },
                  }"
                  v-for="(reports, index) in row.item.reports"
                  :key="index"
                  :title="reports.report_title"
                  v-show="reports.report_title !== 'Statistics Tables' && reports.report_title !== 'Course Statistics' && reports.report_title !== 'Scoring Statistics'"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-nav>
        </template>
        <template #cell(full_name)="row">
          <b-nav-item
            class="tourLink"
            :to="'/tournament/' + row.item.code + '/' + id"
            :class="{major : row.item.major == 'Y' }"
          >
            {{ row.item.full_name }}
          </b-nav-item>
        </template>
        <template #cell(start_date)="row" >
          <span :class="{major : row.item.major == 'Y'}">
            {{ row.item.start_date }}
          </span>
        </template>
        <template #cell(course)="row">
          <span :class="{major : row.item.major == 'Y'}">
            {{ row.item.course }}
          </span>
        </template>
        <template #cell(total_prize_fund)="row">
          <span :class="{major : row.item.major == 'Y'}">
            {{ row.item.total_prize_fund }}
          </span>
        </template>
      </b-table>
    </div>
  </b-card-body>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "fields", "data", "code"],
  filters: {
    base: function(value) {
      return btoa(value);
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.code === item.code) return "live";
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-toggle::after {
  transform: rotate(-90deg);
}
::v-deep .dropleft .dropdown-toggle::after {
  vertical-align: 0;
}
::v-deep .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
::v-deep .dropleft .dropdown-toggle::before {
  display: none;
}
.major {
  color: #263056!important;
  font-weight: bold!important;
}
.major > a {
  color: #263056!important;
  font-weight: bold!important;
}
::v-deep .live > td {
  color: #fff;
}
::v-deep .live > td > span {
  color: #fff!important;
}
li {
  list-style: none;
}
.card-body {
  padding-left: 0;
  padding-right: 0;
}
::v-deep table {
  text-align: left;
}
::v-deep th:first-child {
  text-align: center;
}
::v-deep td:first-child {
  text-align: center;
}
::v-deep th {
  position: sticky;
  top: 0;
  background-color: #263056;
  z-index: 111;
  font-weight: normal;
  text-align: left;
}
::v-deep td {
  font-size: 1rem !important;
  line-height: 18px;
  color: #848792;
  vertical-align: middle;
  padding: 1px 1rem;
  text-align: left;
}
.tourLink > a {
  font-weight: bold;
  padding: 0.5rem 0;
}
.tourLink > a:hover {
  text-decoration: underline;
}
::v-deep .live {
  color: white;
  background-color: #263056 !important;
}
::v-deep .live > td > li > a {
  color: white !important;
}

::v-deep .live > td > ul > div > button {
  color: #263056;
  background-color: white;
}
::v-deep .btn-secondary {
  color: #000;
  background-color: #ffffff;
  text-align: left;
  font-weight: normal;
}
.dropdown {
  border: 1px solid black;
}
::v-deep .dropdown > button {
  font-size: 13px;
  padding-top: 2px;
  height: 20px;
}
::v-deep .dropdown-toggle::before {
  transform: rotate(-90deg);
}
@media only screen and (max-width: 1222px) {
  ::v-deep button::after {
    float: right;
    margin-top: -12px!important;
    position: relative;
    right: -7px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .dropdown-toggle {
    font-size: 14px;
  }
  ::v-deep .dropdown {
    /* width: 92%; */
    width: 100%;
  }
  ::v-deep tr > th:nth-child(4) {
    display: none;
  }
  ::v-deep tr > td:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep tr > td:nth-child(3) {
    display: none;
  }
  ::v-deep tr > th:nth-child(3) {
    display: none;
  }
}
</style>
