<template>
  <div>
    <b-card title="Card Title" no-body class="card-remove-border">
      <b-card-header header-tag="nav" class="dropdown-seasons">
        <b-nav card-header pills>
          <b-dropdown :text="this.id + ' Season'">
            <b-dropdown-item
              v-on:click="changeYear"
              v-for="(seasons,
              index) in tm_params.seasons.seasons_entry.slice().reverse()"
              :key="index"
              :title="seasons.code"
              :year="seasons.code"
              >{{ seasons.desc }}</b-dropdown-item
            >
          </b-dropdown>
        </b-nav>
      </b-card-header>
      <ScheduleTable
        :fields="fields"
        :id="id"
        :data="data"
        :code="tm_params.code"
      />
    </b-card>
  </div>
</template>

<script>
import ScheduleTable from "@/components/scheduleTable.vue";
import apiCall from "@/services/api.js";
export default {
  name: "schedule",
  props: [],
  components: {
    ScheduleTable,
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],

      fields: [
        { key: "start_date", label: "Dates" },
        { key: "full_name", label: "Tournament" },
        { key: "course", label: "Venue" },
        { key: "total_prize_fund", label: "Prize Fund" },
        { key: "reports", label: "Reports" },
      ],
    };
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        this.callTwo(id)
      );
    },
    callOne() {
      apiCall
        .tmParamsBase()
        .then(({ data }) => {
          this.tm_params = data;
          this.id = data.tm_params.season_code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return console.log("one");
    },
    callTwo() {
      apiCall
        .tmParamsYear(this.id)
        .then(({ data }) => {
          this.data = data.tournaments.tournaments_entry;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return console.log("two");
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParamsYear(this.id);
      this.data = second_call.data.tournaments.tournaments_entry;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.card-remove-border {
  border: none;
}
nav.card-header.dropdown-seasons {
  padding-left: 30px;
  background: none;
  border: none;
  border-top: 0px;
}
::v-deep button {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #263056;
  color: #ffffff;
  border-radius: 0px;
  border: 0px;
  height: 30px;
  width: 100%;
  text-align: left;
}
::v-deep button::after {
  float: right;
  margin-top: 8px;
}
.dropdown {
  width: 25%;
}
::v-deep button:hover {
  color: #263056;
  background-color: white;
}
::v-deep th {
  color: white;
  background-color: #263056;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
@media only screen and (max-width: 425px) {
  .dropdown {
    width: 40%;
  }
}
</style>
