<template>
  <div class="dropMenu">
    <b-dropdown :text="id">
      <b-dropdown-item
        v-on:click="changeYear"
        v-for="(seasons, index) in records.slice().reverse()"
        :key="index"
        :title="seasons.CODE"
        :year="seasons.code"
        v-show="seasons.SEASON_ENTRY === 'Y'"
      >
        <template v-if="seasons.SEASON_ENTRY === 'Y'">
          {{ seasons.NAME }}
        </template>
        <template v-else :class="NoEntry"> </template>
      </b-dropdown-item>
    </b-dropdown>
    <h2></h2>
    <b-table :items="data" :fields="fields"> </b-table>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "playerresults",
  props: ["playedSeasons", "refno", "id", "config"],
  data() {
    return {
      data: [],
      noEntry: "noEntry",
      fields: [
        { key: "tourn_start", label: "Date" },
        { key: "tourn_name", label: "Name" },
        { key: "final_pos", label: "Pos" },
        { key: "r1_score", label: "Round" },
        { key: "t_score", label: "Score" },
        { key: "winnings", label: "Winnings (EUR)" },
      ],
    };
  },
  computed: {
    records: function() {
      return this.playedSeasons.filter(
        (season) => season.MEMBER_NAME !== "???? Missing ???"
      );
    },
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (this.id = id), this.callOne();
    },
    callOne() {
      apiCall
        .playersEnhance(this.refno, this.id)
        .then(({ data }) => {
          this.data = data.tourns.tourn_details;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  created() {
    this.callOne();
  },
};
</script>

<style scoped>
.noEntry {
  display: none;
}
.dropMenu {
  text-align: center;
  padding-top: 10px;
}
.dropdown {
  width: 40%;
}

::v-deep .btn {
  background-color: white;
  color: black;
  text-align: left;
}

::v-deep .btn:after {
  float: right;
  margin-top: 10px;
}
::v-deep td {
  text-align: center;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;
}
::v-deep thead {
  color: white;
  background-color: #263056;
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #dfe1e6;
}
::v-deep td:nth-child(2) {
  text-align: left;
}
::v-deep th:nth-child(2) {
  text-align: left;
}
</style>
