<template>
  <div>
    <b-container class="newslist">
      <b-row>
        <b-col>
          <h2 class="article-heading">
            <span v-html="postInfo.title.rendered"></span>
          </h2>
          <!-- <div class="bimg"> -->
          <div>
            <b-img
              v-if="postInfo.better_featured_image !== null"
              class="artImg"
              :src="
                config.VUE_APP_WP_DIGITAL_OCEAN +
                  postInfo.better_featured_image.media_details.file
              "
              fluid
              alt="Responsive image"
            />
          </div>
          <div class="captionText">
            {{ postInfo.cc_featured_image_caption.caption_text }}
          </div>
          <div class="mainArt">
            <span
              v-html="postInfo.content.rendered"
              class="newsDescript"
            ></span>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="allPosts">
      <b-row>
        <b-col>
          <h3 class="latest">
            Latest Alps Tour Golf News
          </h3>
        </b-col>
      </b-row>
      <b-row class="latestAlpsNews">
        <b-col class="postRow">
          <Post :config="config" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Post from "@/components/posts.vue";
export default {
  name: "postFull",
  props: ["id", "config"],
  components: {
    Post
  },
  data() {
    return {
      postInfo: []
    };
  },
  created() {
    const json = "wp/v2/posts/" + this.id;
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.postInfo = data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.captionText {
  background-color: #263056;
  margin-top: 10px;
  color: #fff;
  font-size: 13pt;
  padding: 10px;
}
.newslist {
  margin-top: 2em;
}
h2.article-heading {
  color: #263056;
}
.newsDescript {
  font-size: 14px;
  padding: 20px 0;
}
.mainArt {
  padding: 25px 0;
}
.latest {
  background-color: #dfe1e6;
  color: #263056;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bimg {
  overflow: hidden;
  height: 500px;
}
.artImg {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .bimg {
    overflow: hidden;
    height: 300px;
  }
}
@media only screen and (max-width: 990px) {
  .latestAlpsNews {
    padding-left: 15px;
    padding-right: 15px;
  }
  ::v-deep #leftMore {
    color: #fff;
    background-color: #263056;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    padding: 3px;
    text-align: left;
    vertical-align: bottom;
  }
  ::v-deep .moreNews>a  {
    background-color: transparent;
    color: #263056!important;
    text-align: left;
    /* vertical-align: middle; */
    margin-top: -12px;
    margin-bottom: 15px;
    padding-left: 5px;
  }
}
</style>
