<template>
  <b-table :fields="fields" :items="stats"> </b-table>
</template>

<script>
export default {
  name: "playerstats",
  props: ["stats"],
  data() {
    return {
      fields: [
        { key: "DESCRIPTION", label: "Description" },
        { key: "SEASONS", label: "Seasons" },
        { key: "TOURNAMENTS", label: "Tournaments" },
        { key: "VALUE", label: "Result" },
      ],
    };
  },
};
</script>

<style scoped>
::v-deep thead {
  color: white;
  background-color: #263056;
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #dfe1e6;
}
::v-deep td {
  text-align: center;
  padding: 6px 6px 6px 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;

}
::v-deep th {
  padding: 13px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}
::v-deep td:first-child{
  text-align: left;
  width: 20%;
}
::v-deep th:first-child{
  text-align: left;
}
</style>
