var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playerarea table-responsive"},[_c('b-row',[_c('b-col',{staticClass:"playerInfomation",attrs:{"lg":"6","md":"12"}},[_c('h3',[_vm._v(" Tournament Info & Fact Sheets ")]),_c('p',[_vm._v(" Dear Players, "),_c('br'),_vm._v(" Please find below a list of all tournaments this season. By selecting the reports you will be able to find all associated files, i.e: Tournament Info and Accommodation Details. ")])]),_c('b-col',{staticClass:"playerInfomation leftPad",attrs:{"lg":"6","md":"12"}},[_c('h3',[_vm._v(" Player Information ")]),_c('div',[_c('b-dropdown',{staticClass:"m-md-2 mob",attrs:{"id":"dropdown-1","text":"Select Report..."}},_vm._l((_vm.data.tm_params.season_files
              .season_file_details),function(files,index){return _c('b-dropdown-item',{key:index,attrs:{"target":"_black","href":'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/factsheets/' +
                _vm.data.tm_params.season_code +
                '/' +
                files.season_file}},[_vm._v(" "+_vm._s(files.season_desc)+" ")])}),1)],1)])],1),_c('b-table',{attrs:{"items":_vm.data.tournaments.tournaments_entry,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(total_prize_fund)",fn:function(row){return [(Object.keys(row.item.total_prize_fund).length === 0)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(row.item.total_prize_fund)+" ")])]}},{key:"cell(reports)",fn:function(row){return [_c('b-nav',{attrs:{"card-header":"","pills":""}},[(Array.isArray(row.item.fact_sheets.fact_sheet))?_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","text":"Report..."}},_vm._l((row.item.fact_sheets.fact_sheet.slice(
              0,
              6
            )),function(reports,index){return _c('b-dropdown-item',{key:index,attrs:{"target":"_blank","href":reports.fact_sheet_file.replace('https://alps.ocs-ffg.com/', 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/' ),"title":reports.fact_sheet_desc}},[(Object.entries(reports.fact_sheet_desc).length)?_c('span',[_vm._v(" "+_vm._s(reports.fact_sheet_desc)+" ")]):_vm._e()])}),1):_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","text":"report"}},_vm._l((row.item.reports),function(reports,index){return _c('b-dropdown-item',{key:index,attrs:{"target":"_blank","href":reports.fact_sheet_file.replace('https://alps.ocs-ffg.com/', 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/' ),"title":reports.fact_sheet_desc}},[(Object.entries(reports.fact_sheet_desc).length)?_c('span',[_vm._v(" "+_vm._s(reports.fact_sheet_desc)+" ")]):_vm._e()])}),1)],1)]}},{key:"cell(short_name)",fn:function(row){return [_c('a',{attrs:{"href":'/tournament/' + row.item.code + '/' + _vm.data.tm_params.season_code}},[_vm._v(" "+_vm._s(row.item.short_name)+" ")])]}},{key:"cell(closing_date)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.closing_date)),_c('span',{staticClass:"mobHide"},[_vm._v(" - "+_vm._s(row.item.closing_time))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }