<template>
  <table>
    <thead>
      <tr>
        <th :class="[cell, fields]">
          Pos
        </th>
        <th :class="[cell, fields]">
          Name
        </th>
        <th :class="[cell, fields]">
          Nat
        </th>
        <th :class="[cell, fields]" style="text-transform: capitalize;">
          {{data.value_2_head}}
        </th>
        <th :class="[cell, fields]">
          {{data.oom_header}}
        </th>
      </tr>
    </thead>
    <tbody v-if="this.id !== this.data.season_code"></tbody>
    <tbody v-else>
      <tr v-if="data.oom_available == 'N'">
        <td colspan="5" id="NS">
          No Stats
        </td>
      </tr>
      <tr v-for="(stats, index) in data.oom.oom_entry" :key="index">
        <td
          v-if="data.oom_available == 'Y'"
          :class="[cell, name]"
          class="posnum"
        >
          <span>
            {{ stats.pos }}
          </span>
        </td>
        <template v-if="stats.profile == 'Y'">
          <td v-if="data.oom_available == 'Y'" :class="[cell, name]" class="playerName">
            <a :href="'/playerprofile/' + stats.playerno">
              {{ stats.forename }} {{ stats.surname }}
              <span v-if="stats.pro_ind === 'Am'">(a)</span>
            </a>
          </td>
        </template>
        <template v-else>
        <td v-if="data.oom_available == 'Y'" :class="[cell, name]" class="playerName">
            {{ stats.forename }} {{ stats.surname }}
            <span v-if="stats.pro_ind === 'Am'">(a)</span>
        </td>
        </template>
        <td v-if="data.oom_available == 'Y'" :class="cell">
          <span>
            <img
              :class="flag"
              :src="(config.VUE_APP_FLAG_URL + stats.nationality) | lowercase"
            />
          </span>
        </td>
        <td v-if="data.oom_available == 'Y'" :class="cell">
          {{ stats.oom_value }}
        </td>
        <td :class="cell" v-if="stat === '-ooms-oom-'+config.VUE_APP_OOM">
          {{ stats.tournaments_played }}
        </td>
        <td :class="cell" v-else>
          {{ stats.rounds_played }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", "config"],
  data() {
    return {
      noData: "noData",
      flag: "flag",
      cell: "cell",
      fields: "fields",
      name: "name",
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.noData {
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  height: 200px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}

.fields {
  background-color: #263056;
  color: white;
  font-weight: 400;
}
::v-deep td {
  text-align: center;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;
}
::v-deep th {
  padding: 13px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}
.cell {
  text-align: center;
}
tr:nth-child(even) {
  background-color: #dee2e6;
}
th {
  position: sticky;
  top: 0;
}
th:nth-child(2) {
  text-align: left;
}
td:nth-child(2) {
  text-align: left;
  width: 20%;
}

#NS {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 2%;
  padding-bottom: 2%;
}
td.posnum {
  padding: 5px;
  background-color: #263056;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 480px) {
  .noData {
    height: 50vh;
  }
  .cell {
    width: 1%;
  }
  .name {
    /* width: 3%; */
    width: auto;
  }
  th:nth-child(3) {
    display: none;
  }
  td:nth-child(3) {
    display: none;
  }
  .playerName {
    padding-left: 15px;
  }
}
</style>
