<template>
  <div>
    <div class="newSearch">
      <b-container>
        <b-jumbotron :header="'Search \'' + filter + '\''">
          <b-form-input
            class="seachInput"
            v-model="text"
            placeholder="Search"
          ></b-form-input>
          <b-button
            :to="{
              name: 'search',
              query: { search: text },
            }"
            class="sIcon"
          >
            <font-awesome-icon :icon="['fa', 'search']" />
          </b-button>
        </b-jumbotron>
      </b-container>
    </div>
    <b-container>
      <b-table
        v-if="filter"
        :items="allposts"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
      >
        <template #cell(better_featured_image)="row">
          <b-nav-item :to="'/news/' + row.item.id">
            <h2 v-html="row.item.title.rendered"></h2>
          </b-nav-item>
          <span v-html="row.item.excerpt.rendered"></span> </template
      ></b-table>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "search",
  data() {
    return {
      allposts: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text:''
    };
  },
  created() {
    const json = "wp/v2/posts?per_page=100";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.allposts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
h2 {
  font-size: 30px;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px !important;
}
::v-deep .jumbotron {
  padding-left: 0;
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5em 0;
}
::v-deep table {
  margin-top: 75px;
}
.newSearch {
  background-color: #eee;
  margin-top: 75px;
}
::v-deep .display-3 {
  font-size: 30px;
  font-weight: 500;
}
.seachInput {
  width: 25%;
  display: inline-block;
  margin-top: 1em;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  padding: 5px 11px;
  border-radius: 5px;
}
</style>
